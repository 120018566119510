// Import the info icon
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Icon for opening a new tab
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import { SystemInformationsService } from 'api/system';

import Loader from 'components/Loader/Loader';

import { SystemInformationsProps } from './SystemInformations.definition';

function SystemInformations({
  t,
}: SystemInformationsProps): FunctionComponentElement<SystemInformationsProps> {
  const { isFetching, data } = useQuery({
    queryKey: ['SystemInformations'],
    queryFn: async () => SystemInformationsService.getApiSystemInformations(),
  });

  if (isFetching) {
    return <Loader />;
  }

  const handleOpenReleaseNotes = () => {
    window.open('/release-notes.md', '_blank');
  };

  return (
    <Box
      sx={{
        ml: '0.5rem',
        mr: '0.5rem',
      }}
    >
      <Stack>
        <Typography variant="overline" display="block" sx={{ lineHeight: 'inherit' }}>
          {data?.environment}
        </Typography>
        <Typography
          variant="caption"
          display="block"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {`Version: ${data?.version}`}
          <Tooltip title={t('releaseNotesTooltip')}>
            <IconButton
              size="small"
              onClick={handleOpenReleaseNotes}
              sx={{ ml: '0.1rem', color: 'white' }}
            >
              <OpenInNewIcon fontSize="inherit" sx={{ fontSize: '0.70rem' }} />
            </IconButton>
          </Tooltip>
        </Typography>
      </Stack>
    </Box>
  );
}

export default withTranslation('systemInformations')(SystemInformations);
