import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import DisplayDeadlines from 'components/DisplayDeadlines/DisplayDeadlines';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';
import Loader from 'components/Loader/Loader';

import { getFillingPeriodDeadlinesQuery } from '../CreateTaxFolderStepper/CreateTaxFolderStepper.helper';

import { FillingPeriodDeadlinesStepProps } from './FillingPeriodDeadlinesStep.definitions';

function FillingPeriodDeadlinesStep({
  taxFolderStepperForm,
  updateTaxFolderStepperForm,
  goToNextStep,
  goToPrevStep,
  t,
}: FillingPeriodDeadlinesStepProps): FunctionComponentElement<FillingPeriodDeadlinesStepProps> {
  const {
    selectedOpenTaxYear,
    selectedAdvanceVatReturnInterval,
    selectedEcSalesListInterval,
    permanentPeriodExtension,
  } = taxFolderStepperForm;

  const year = selectedOpenTaxYear && selectedOpenTaxYear.year ? selectedOpenTaxYear.year : 0;

  const {
    isFetching,
    data: calculatedDeadlines,
    error,
  } = useQuery({
    queryKey: ['fillingPeriodDeadlines'],
    queryFn: () =>
      getFillingPeriodDeadlinesQuery(
        year,
        selectedAdvanceVatReturnInterval,
        selectedEcSalesListInterval,
        permanentPeriodExtension,
      ),
  });

  const handleNextClick = () => {
    const updatedInfo = {
      ...taxFolderStepperForm,
      calculatedDeadlines,
    };
    updateTaxFolderStepperForm(updatedInfo);
    goToNextStep();
  };

  const handlePrevClick = () => {
    goToPrevStep();
  };

  if (error) {
    return <FallbackComponent error={error} />;
  }

  if (isFetching) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <div className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <DisplayDeadlines deadlines={calculatedDeadlines} />
      </Box>
      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!calculatedDeadlines}
            onClick={handleNextClick}
          >
            {t('common:next')}
          </Button>
        </Stack>
      </FormFooter>
    </div>
  );
}

export default withTranslation(['createTaxFolderStepper', 'common'])(
  observer(FillingPeriodDeadlinesStep),
);
