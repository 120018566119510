import { Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { TaxSummaryService } from 'api/invoices';
import { AnnualVatReturnsService, FillingPeriodsService } from 'api/taxCalculation';

import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import DeletionApprovalDialog from 'components/DeletionApprovalDialog/DeletionApprovalDialog';
import InformationCard from 'components/InformationCard/InformationCard';

import NamedApprovalStatus from 'enums/NamedApprovalStatus';

import { TaxYearProps } from './TaxYear.definitions';

function TaxYear({ t }: TaxYearProps): FunctionComponentElement<TaxYearProps> {
  const [isMutating, setIsMutating] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [deleteSubmission, setDeleteSubmission] = useState(false);

  const { countryId, taxYearId } = useParams();
  const queryClient = useQueryClient();

  const { data: taxYearInformation, isFetching } = useQuery({
    queryKey: ['getTaxYearTaxInformation'],
    queryFn: async () => {
      const taxYearInformationData = await TaxSummaryService.getApiTaxSummaryGetTaxYearSummaries(
        countryId,
        taxYearId,
      );

      return taxYearInformationData;
    },
  });

  const { mutate: deleteFillingPeriod } = useMutation({
    mutationFn: async (fillingPeriodId: string) => {
      const response = await FillingPeriodsService.deleteApiFillingPeriodsDelete(fillingPeriodId);
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['navigation'] });
      await queryClient.invalidateQueries({ queryKey: ['getTaxYearTaxInformation'] });
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['deleteFillingPeriod'],
  });

  const { mutate: deleteAnnualVatReturn } = useMutation({
    mutationFn: async (annualVatReturnId: string) => {
      const response =
        await AnnualVatReturnsService.deleteApiAnnualVatReturnsDelete(annualVatReturnId);
      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['navigation'] });
      await queryClient.invalidateQueries({ queryKey: ['getTaxYearTaxInformation'] });
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    mutationKey: ['deleteAnnualVatReturn'],
  });

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpen(false);

    if (deleteSubmission) {
      deleteAnnualVatReturn(selectedId);
    } else {
      deleteFillingPeriod(selectedId);
    }
  };

  const handleOpenFillingPeriod = (fillingPeriodId: string) => {
    setSelectedId(fillingPeriodId);
    setDeleteSubmission(false);
    setOpen(true);
  };

  const handleOpenAnnualVatReturn = (annualVatReturnId: string) => {
    setSelectedId(annualVatReturnId);
    setDeleteSubmission(true);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  if (isFetching || isMutating) {
    return <CenteredLoader />;
  }

  return (
    <div style={{ height: '100%' }}>
      <Grid container spacing={2}>
        {taxYearInformation?.map((element) => {
          const isDeletable = element.isDeletable ?? false;

          let deleteFunction;

          if (isDeletable && element.annualVatReturnModel !== undefined) {
            deleteFunction = () =>
              handleOpenAnnualVatReturn(element.annualVatReturnModel?.id ?? '');
          } else if (isDeletable) {
            deleteFunction = () => handleOpenFillingPeriod(element.fillingPeriodId ?? '');
          }

          return (
            <Grid item xs={6} lg={4} key={element?.fillingPeriodId ?? v4()}>
              {element.annualVatReturnModel !== undefined ? (
                <InformationCard
                  subtitle={element?.reportingYear?.toString() || ''}
                  title={t('taxYearSummaryFields:annual-vat-return', {
                    version: Number(element?.version) > 1 ? `- ${element.version}` : '',
                  })}
                  bodyText={
                    <Trans i18nKey="taxYearSummaryFields">
                      {(element?.annualVatReturnModel &&
                        element.annualVatReturnModel.dueDate &&
                        t('tax-information-withAnnualVatReturn', {
                          year: element?.reportingYear?.toString() || '',
                          annualVatDate: new Date(
                            element.annualVatReturnModel.dueDate,
                          ).toLocaleDateString('de-DE'),
                        })) ||
                        ''}
                    </Trans>
                  }
                  buttonText={t('taxYearSummaryFields:cta-button-annual-vat-return')}
                  navigatePath={`annual-vat-return/${element.annualVatReturnModel?.id}`}
                  isDeletable={!isDeletable}
                  deleteEntity={deleteFunction}
                />
              ) : (
                <InformationCard
                  subtitle={element?.reportingYear?.toString() || ''}
                  title={
                    <Trans i18nKey="taxYearSummaryFields">
                      {t('taxYearSummaryFields:reportingMonth', {
                        month: String(element?.reportingMonth) || '',
                        version: Number(element?.version) > 1 ? `- ${element.version}` : '',
                      })}
                    </Trans>
                  }
                  bodyText={
                    <Trans i18nKey="taxYearSummaryFields">
                      {(element.advanceVatReturnModel?.dueDate &&
                        element.ecSalesList?.dueDate &&
                        t('tax-information-withAdvanceVatReturn', {
                          ecSalesDate: new Date(
                            element.ecSalesList?.dueDate || '',
                          ).toLocaleDateString('de-DE'),
                          month: element?.reportingMonth?.toString() || '',
                          advanceVatReturnDate: new Date(
                            element.advanceVatReturnModel.dueDate,
                          ).toLocaleDateString('de-DE'),
                        })) ||
                        (element.advanceVatReturnModel?.dueDate &&
                          !element.ecSalesList?.dueDate &&
                          t('tax-information-withoutEcSalesList', {
                            month: element?.reportingMonth?.toString() || '',
                            advanceVatReturnDate: new Date(
                              element.advanceVatReturnModel.dueDate,
                            ).toLocaleDateString('de-DE'),
                          })) ||
                        (element?.ecSalesList?.dueDate &&
                          t('taxYearSummaryFields:tax-information-withoutAdvanceVatReturn', {
                            ecSalesDate: new Date(element.ecSalesList.dueDate).toLocaleDateString(
                              'de-DE',
                            ),
                            month: element?.reportingMonth?.toString() || '',
                          })) ||
                        t('taxYearSummaryFields:tax-information-withoutAnyDeadlines', {
                          month: element?.reportingMonth?.toString() || '',
                        })}
                    </Trans>
                  }
                  buttonText={t('taxYearSummaryFields:cta-button')}
                  navigatePath={`filling-period/${element?.fillingPeriodId}`}
                  deleteEntity={deleteFunction}
                  isDeletable={!isDeletable}
                >
                  <Grid container spacing={0} fontSize="small" paddingTop="0.5rem">
                    {element.advanceVatReturnApprovalStatus !== undefined && (
                      <>
                        <Grid item xs={5}>
                          {t('submissions:AdvancedVatReturnApprovalStatus')}
                        </Grid>
                        <Grid item xs={7}>
                          {t(
                            `submissions:${
                              NamedApprovalStatus[Number(element.advanceVatReturnApprovalStatus)]
                            }`,
                          )}
                        </Grid>
                      </>
                    )}
                    {element.ecSalesListApprovalStatus !== undefined && (
                      <>
                        <Grid item xs={5}>
                          {t('submissions:EcSalesListApprovalStatus')}
                        </Grid>
                        <Grid item xs={7}>
                          {t(
                            `submissions:${
                              NamedApprovalStatus[Number(element.ecSalesListApprovalStatus)]
                            }`,
                          )}
                        </Grid>
                      </>
                    )}
                    {element.specialPrepaymentsApprovalStatus !== undefined && (
                      <>
                        <Grid item xs={5}>
                          {t('submissions:SpecialPrepaymentApprovalStatus')}
                        </Grid>
                        <Grid item xs={7}>
                          {t(
                            `submissions:${
                              NamedApprovalStatus[Number(element.specialPrepaymentsApprovalStatus)]
                            }`,
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </InformationCard>
              )}
            </Grid>
          );
        })}
      </Grid>
      <DeletionApprovalDialog
        open={open}
        title={t('taxYearSummaryFields:deletion-dialog-delete-title')}
        message={t('taxYearSummaryFields:dialog-delete-warning')}
        switchLabel={t('taxYearSummaryFields:dialog-switch-label')}
        handleClose={handleClose}
        handleFormSubmit={handleFormSubmit}
      />
    </div>
  );
}

export default withTranslation(['taxYearSummaryFields', 'navigation', 'submissions', 'dialogs'])(
  TaxYear,
);
