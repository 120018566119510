import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton } from '@mui/material';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import NamedSubmissionStatus from 'enums/NamedSubmissionStatus';

import { ValidationStateIconProps } from './ValidationStateIcon.definitions';

function ValidationStateIcon({
  validationState,
  submissionStatus,
  t,
}: ValidationStateIconProps): FunctionComponentElement<ValidationStateIconProps> {
  if (submissionStatus !== null && Number(submissionStatus) === NamedSubmissionStatus.Submitted) {
    return (
      <IconButton disableFocusRipple disableRipple color="success">
        <MailOutlineIcon />
      </IconButton>
    );
  }

  if (validationState === null || validationState === undefined) {
    return (
      <IconButton disableFocusRipple disableRipple color="info">
        <HelpOutlineIcon />
      </IconButton>
    );
  }

  const iconMap: Record<number, React.ReactNode> = {
    1: <AccessTimeIcon />,
    2: <CheckCircleOutlineIcon />,
    3: <WarningAmberIcon />,
    4: <WarningAmberIcon />,
    5: <ReportGmailerrorredIcon />,
  };

  const colorMap: Record<number, string> = {
    1: 'info',
    2: 'success',
    3: 'warning',
    4: 'warning',
    5: 'error',
  };

  const icon = iconMap[validationState] || <HelpOutlineIcon />;
  const color = colorMap[validationState] || 'info';

  return (
    <IconButton
      disableFocusRipple
      disableRipple
      color={
        color as
          | 'info'
          | 'inherit'
          | 'default'
          | 'primary'
          | 'secondary'
          | 'error'
          | 'success'
          | 'warning'
      }
    >
      {icon}
    </IconButton>
  );
}

export default withTranslation('taxValidationStates')(ValidationStateIcon);
