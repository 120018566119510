import { Block, TrafficOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import TrafficLightTooltip from '../Tooltips/TrafficLightTooltip';

import { ValidationStateIconCellProps } from './ValidationStateIconCell.definitions';

function ValidationStateIconCell({ props, messages, state }: ValidationStateIconCellProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['validationStates', 'validationMessages']);

  const iconMap: { [key: string]: JSX.Element } = {
    '0': <TrafficOutlined color="disabled" />,
    '1': <TrafficOutlined color="action" />,
    '2': <TrafficOutlined color="success" />,
    '3': <TrafficOutlined color="warning" />,
    '4': <TrafficOutlined color="warning" />,
    '5': <TrafficOutlined color="error" />,
    ignored: <Block color="action" />,
    default: <TrafficOutlined color="disabled" />,
  };

  const colorMap: { [key: string]: string } = {
    '0': 'disabled',
    '1': 'action',
    '2': 'success',
    '3': 'warning',
    '4': 'warning',
    '5': 'error',
    ignored: 'action',
    default: 'disabled',
  };

  const formattedMessages = (
    <>
      <Typography
        variant="subtitle2"
        color={colorMap[state]}
        gutterBottom
        sx={{ display: 'block' }}
      >
        {t(`validationStates:${state}`)}
      </Typography>
      {messages
        .filter((x) => x.length !== 0)
        .map((message) => (
          <div key={v4()}> - {t(`validationMessages:${message}`, message)}</div>
        ))}
    </>
  );

  const icon = iconMap[state] || iconMap.default;
  const color = colorMap[state] || colorMap.default;

  return (
    <TrafficLightTooltip
      color={color}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      open={isOpen}
      title={formattedMessages}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: false,
                rootBoundary: 'document',
                padding: 8,
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: false,
                altBoundary: false,
                tether: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ],
        },
      }}
    >
      {icon}
    </TrafficLightTooltip>
  );
}

export default function RenderValidationStateIcon(
  params: GridRenderCellParams,
  messages: string[],
  color: string,
) {
  return <ValidationStateIconCell props={params} messages={messages} state={color} />;
}
