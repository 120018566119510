import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import { IconButton } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridGroupingValueGetterParams,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridValueSetterParams,
  ValueOptions,
} from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TaxCodesService } from 'api/client';
import {
  CommentModel,
  InvoiceModel,
  InvoiceRowModel,
  InvoicesService,
  OriginState,
  TaxValidationModel,
} from 'api/invoices';
import { TaxRuleModel, TaxRulesService } from 'api/taxRule';

import useStore from 'context/Store/Store.hooks';
import useFillingPeriod from 'shared/api-hooks/useFillingPeriod';
import { dateFormat } from 'shared/utils/date.helper';
import {
  getGroupingKey,
  isAutoGeneratedColumn,
  isGroupTypeColumn,
  isGroupingColumn,
} from 'shared/utils/grid.helper';
import { numberFormatter } from 'shared/utils/invoice.helper';
import GridFieldsValidator from 'shared/utils/validators/GridFieldsValidator';

import BdoDataGrid from 'components/BdoDataGrid/BdoDataGrid';
import RenderBasicEditCell from 'components/BdoDataGrid/Cells/BasicEditCell';
import InvoiceOriginCell from 'components/BdoDataGrid/Cells/InvoiceOriginCell/InvoiceOriginCell';
import ModificationStateCell from 'components/BdoDataGrid/Cells/ModificationStateCell/ModificationStateCell';
import RenderValidationStateIcon from 'components/BdoDataGrid/Cells/ValidationStateIconCell';
import { DataGridRow } from 'components/BdoDataGrid/Types/DataGridRow.definitions';
import CenteredLoader from 'components/CenteredLoader/CenteredLoader';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import InvoiceTable from 'components/InvoiceTable/InvoiceTable';
import PopupModal from 'components/PopupModal/PopupModal';

import CommentEntityType from 'enums/CommentEntityType';
import InvoiceOriginState from 'enums/InvoiceOriginState';
import RightDrawerContentType from 'enums/RightDrawerContentType';
import { CommentsDrawerGridContext } from 'models/CommentsDrawerGridContext';

import { CompanyInvoicesGridProps } from './InvoicesGrid.definitions';

interface CellStateMapping {
  [invoiceId: string]: { [fieldName: string]: string };
}

function InvoicesGrid({
  companyId,
  t,
}: CompanyInvoicesGridProps): FunctionComponentElement<CompanyInvoicesGridProps> {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<InvoiceModel>({} as InvoiceModel);
  const [comparisonInvoice, setComparisonInvoice] = useState<InvoiceModel>({} as InvoiceModel);

  const cellStateMapping: CellStateMapping = {};

  const {
    rightDrawerStore: { setCommentsDrawerGridRowContext, setActiveContent, setOpen },
  } = useStore();

  const { fillingPeriodId } = useParams();

  const {
    isFetching: isFetchingTaxCodes,
    data: taxCodes,
    error: isErrorTaxCodes,
  } = useQuery({
    queryKey: ['getTaxCodes'],
    queryFn: async () => {
      const taxCodesData = await TaxCodesService.getApiTaxCodes();

      return taxCodesData;
    },
  });

  const {
    isFetching: isFetchingFillingPeriod,
    fillingPeriod,
    isReadonly,
  } = useFillingPeriod(fillingPeriodId);

  const { isFetching: isFetchingTaxRules, data: taxRules } = useQuery({
    queryKey: ['getTaxRules'],
    queryFn: async () => TaxRulesService.getApiTaxRules(),
  });

  const handleCommentClick = (): void => {
    setActiveContent(RightDrawerContentType.Comments);
    setOpen(true);
  };

  const handleRowClick = (params: GridRowParams) => {
    const commentsDrawerContext: CommentsDrawerGridContext = {
      entityType: CommentEntityType.Invoice,
      referenceId: params.row?.id,
    };
    setCommentsDrawerGridRowContext(commentsDrawerContext);
  };

  const handleOpenModal = (originalInvoice: InvoiceModel, currentInvoice: InvoiceModel): void => {
    setModalOpen(true);
    setModalData(originalInvoice);
    setComparisonInvoice(currentInvoice);
  };

  const handleCloseModal = (): void => {
    setModalOpen(false);
  };

  const getCellClassName = (params: GridCellParams) => {
    const { id } = params.row;

    if (cellStateMapping[id]) {
      const fieldValidationState = cellStateMapping[id][params.field];

      if (fieldValidationState === '1' || fieldValidationState === '2') {
        return `validation-state--${cellStateMapping[id][params.field] || 'none'}`;
      }
    }

    return '';
  };

  const getTaxCodesValueOptions = (): ValueOptions[] => {
    if (!taxCodes) return [];

    const mappedTaxCodes = taxCodes.map(
      (taxCode): ValueOptions => ({
        value: taxCode.id,
        label: taxCode.code,
      }),
    );

    const result: ValueOptions[] = [...mappedTaxCodes];

    return result;
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: t('invoice:id'), width: 150, type: 'string', disableExport: true },
    {
      field: 'companyId',
      headerName: t('invoice:companyId'),
      width: 150,
      type: 'string',
      disableExport: true,
    },
    {
      field: 'hasComments',
      headerName: `${t('invoice:comments')}`,
      type: 'boolean',
      width: 150,
      editable: false,
      disableExport: true,
      renderCell: (params: GridRenderCellParams) => {
        if (isGroupTypeColumn(params) && !isGroupingColumn(params)) {
          return '';
        }

        if (params.value) {
          return (
            <IconButton
              aria-label="comment-enabled"
              size="small"
              sx={{ color: (theme) => theme.yellow.main }}
              onClick={handleCommentClick}
            >
              <CommentTwoToneIcon fontSize="small" />
            </IconButton>
          );
        }
        return (
          <IconButton
            aria-label="comment-disabled"
            size="small"
            sx={{ color: (theme) => theme.palette.grey[500] }}
            onClick={handleCommentClick}
          >
            <CommentTwoToneIcon fontSize="small" />
          </IconButton>
        );
      },
    },
    {
      field: 'comments',
      headerName: `${t('invoice:comments')}`,
      width: 150,
      editable: false,
      disableExport: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        const comments: CommentModel[] = params.value as CommentModel[];

        if (!comments || comments.length === 0) {
          return '';
        }

        return comments
          .map(
            (comment) =>
              `${new Date(comment.created ?? '').toLocaleString('de-DE', {
                dateStyle: 'medium',
                timeStyle: 'medium',
              })}, ${comment.createdBy}:\n${comment.value}`,
          )
          .join('\n\n');
      },
    },
    {
      field: 'modificationState',
      headerName: t('invoice:modificationStateHeader'),
      type: 'string',
      width: 75,
      editable: false,
      align: 'center',
      renderCell: (params) => {
        if (isGroupTypeColumn(params) && !isGroupingColumn(params)) {
          return '';
        }

        const { originalInvoice } = params.row;

        return (
          <ModificationStateCell
            modificationState={params.value}
            originalInvoice={originalInvoice}
            currentInvoice={params.row as InvoiceModel}
            tooltipTitle={t(`invoice:modificationState${params.value}`)}
            handleOpenModal={handleOpenModal}
          />
        );
      },
      valueFormatter: (params) => t(`invoice:modificationState${params.value}`),
    },
    {
      field: 'origin',
      headerName: t('invoice:origin'),
      type: 'string',
      width: 75,
      editable: false,
      align: 'center',
      renderCell: (params: GridRenderCellParams<InvoiceRowModel>) => {
        if (isGroupTypeColumn(params) && !isGroupingColumn(params)) {
          return '';
        }

        if (isGroupTypeColumn(params) && isGroupingColumn(params)) {
          const state = Number(getGroupingKey(params));

          return <InvoiceOriginCell title={t(`invoice:originStateGroup${state}`)} state={state} />;
        }

        if (!params.row.company) {
          return null;
        }

        const { origin } = params.row;
        const { fillingPeriod: companyFillingPeriod } = params.row.company;

        if (!companyFillingPeriod) {
          return null;
        }

        let formattedFillingPeriod = '';

        // original
        if (origin === OriginState._0) {
          return (
            <InvoiceOriginCell
              title={t('originStateOriginal')}
              state={InvoiceOriginState.Original}
            />
          );
        }

        const { originalCompany } = params.row;
        if (originalCompany && originalCompany.fillingPeriod) {
          const originalFillingPeriod = originalCompany.fillingPeriod;
          formattedFillingPeriod = `${originalFillingPeriod.reportingMonth} / 12 (${originalFillingPeriod.reportingYear})`;
        }

        // shifted out
        if (params.row.companyId !== companyId) {
          formattedFillingPeriod = `${companyFillingPeriod.reportingMonth} / 12 (${companyFillingPeriod.reportingYear})`;
          return (
            <InvoiceOriginCell
              title={t('invoice:originStateShiftedOut', {
                fillingPeriod: formattedFillingPeriod,
              })}
              state={InvoiceOriginState.ShiftedOut}
            />
          );
        }

        // shifted in
        return (
          <InvoiceOriginCell
            title={t(`invoice:originStateShiftedIn`, {
              fillingPeriod: formattedFillingPeriod,
            })}
            state={InvoiceOriginState.ShiftedIn}
          />
        );
      },
      groupingValueGetter: (params) => params.row.origin,
      valueFormatter: (params) => {
        if (params.id) {
          const row = params.api.getRow(params.id);

          const { origin, originalCompany } = row;

          let formattedFillingPeriod = null;

          if (originalCompany && originalCompany.fillingPeriod) {
            const originalFillingPeriod = originalCompany.fillingPeriod;
            formattedFillingPeriod = `${originalFillingPeriod.reportingMonth} / 12 (${originalFillingPeriod.reportingYear})`;
          }

          return t(`invoice:originState${origin}`, {
            originalFillingPeriod: formattedFillingPeriod,
          });
        }

        return params.value;
      },
    },
    {
      field: 'taxCodeId',
      headerName: `${t('invoice:taxCode')} *`,
      width: 150,
      type: 'singleSelect',
      editable: true,
      valueOptions: getTaxCodesValueOptions(),
      valueGetter: (params) => {
        const taxCode = taxCodes?.find((x) => x.id === params.value);

        if (taxCode === undefined) {
          return '';
        }

        return taxCode.id;
      },
      renderCell: (params) => {
        if (isErrorTaxCodes) {
          return <FallbackComponent errorMessage={t('common:errorOccured')} />;
        }

        if (params.rowNode.type === 'group' && params.field === params.rowNode.groupingField) {
          return '';
        }

        const taxCodeId = params?.row?.taxCodeId;
        if (taxCodeId) {
          return taxCodes?.find((x) => x.id === taxCodeId)?.code;
        }

        return params.value;
      },
      valueSetter: (params) => {
        const { value, row } = params;
        const taxCode = taxCodes?.find((x) => x.id === value);

        return { ...row, taxCodeId: taxCode?.id };
      },
      groupingValueGetter: (params) => {
        const name = taxCodes?.find((x) => x.id === params.value)?.code;

        if (name === undefined) {
          return t('common:noSelection');
        }

        return name;
      },
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'invoiceType',
      headerName: `${t('invoice:invoiceType')} *`,
      type: 'singleSelect',
      editable: true,
      width: 150,
      valueOptions: [
        { value: 1, label: t('invoice:incoming') },
        { value: 2, label: t('invoice:outgoing') },
      ],
      valueGetter: ({ row }) => row.invoiceType ?? '',
      valueSetter: (params) => ({
        ...params.row,
        invoiceType: params.value,
      }),
      groupingValueGetter: ({ value }) =>
        value === 1 ? t('invoice:incoming') : t('invoice:outgoing'),
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'invoiceDate',
      headerName: `${t('invoice:invoiceDate')} *`,
      width: 150,
      type: 'date',
      editable: true,
      valueGetter: ({ value }) => (value && new Date(value)) || '',
      renderCell: ({ value }) => {
        const date = new Date(value || '');

        return Number.isNaN(date.getTime()) ? '' : date.toLocaleDateString('de-DE', dateFormat);
      },
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'invoiceNumber',
      headerName: `${t('invoice:invoiceNumber')} *`,
      width: 150,
      type: 'string',
      editable: true,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'reportingMonth',
      headerName: `${t('invoice:reportingMonth')} *`,
      width: 150,
      type: 'number',
      editable: false,
    },
    {
      field: 'reportingYear',
      headerName: `${t('invoice:reportingYear')} *`,
      width: 150,
      type: 'string',
      editable: false,
    },
    {
      field: 'accountingArea',
      headerName: t('invoice:accountingArea'),
      width: 150,
      type: 'string',
      editable: true,
    },
    {
      field: 'bookingNumber',
      headerName: t('invoice:bookingNumber'),
      width: 150,
      type: 'string',
      editable: true,
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'bookingDate',
      headerName: t('invoice:bookingDate'),
      width: 150,
      type: 'date',
      editable: true,
      valueGetter: ({ value }) => (value && new Date(value)) || '',
      renderCell: ({ value }) => {
        const date = new Date(value);

        return Number.isNaN(date.getTime()) ? '' : date.toLocaleDateString('de-DE', dateFormat);
      },
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'dateOfSupply',
      headerName: t('invoice:dateOfSupply'),
      width: 150,
      type: 'date',
      editable: true,
      valueGetter: ({ value }) => (value && new Date(value)) || '',
      renderCell: ({ value }) => {
        const date = new Date(value || '');

        return Number.isNaN(date.getTime()) ? '' : date.toLocaleDateString('de-DE', dateFormat);
      },
      cellClassName: (params) => getCellClassName(params),
    },
    // For businessPartner, you might want to show a name or identifier
    {
      field: 'businessPartner.businessPartnerType',
      headerName: `${t('businessPartner:businessPartnerType')} *`,
      width: 150,
      type: 'singleSelect',
      editable: true,
      valueOptions: [
        { value: 1, label: t('invoice:supplier') },
        { value: 2, label: t('invoice:customer') },
      ],
      valueGetter: ({ row }) => row.businessPartner?.businessPartnerType ?? '',
      valueSetter: (params) => ({
        ...params.row,
        businessPartner: {
          ...params.row.businessPartner,
          businessPartnerType: params.value,
        },
      }),
      groupingValueGetter: (params) => {
        const businessPartnerType = params.row.businessPartner?.businessPartnerType;

        if (businessPartnerType === 1) {
          return t('invoice:supplier');
        }
        if (businessPartnerType === 2) {
          return t('invoice:customer');
        }

        return '';
      },
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'businessPartner.name',
      headerName: t('businessPartner:name'),
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.businessPartner?.name,
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        businessPartner: { ...params.row.businessPartner, name: params.value },
      }),
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        params?.row?.businessPartner?.name,
    },
    {
      field: 'businessPartner.vatTaxNumber',
      headerName: t('businessPartner:vatTaxNumber'),
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.businessPartner?.vatTaxNumber,
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        businessPartner: { ...params.row.businessPartner, vatTaxNumber: params.value },
      }),
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        params?.row?.businessPartner?.vatTaxNumber,
    },
    {
      field: 'businessPartner.street',
      headerName: t('businessPartner:street'),
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.businessPartner?.street,
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        businessPartner: { ...params.row.businessPartner, street: params.value },
      }),
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        params?.row?.businessPartner?.street,
    },
    {
      field: 'businessPartner.number',
      headerName: t('businessPartner:number'),
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.businessPartner?.number,
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        businessPartner: { ...params.row.businessPartner, number: params.value },
      }),
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        params?.row?.businessPartner?.number,
    },
    {
      field: 'businessPartner.postal',
      headerName: t('businessPartner:postal'),
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.businessPartner?.postal,
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        businessPartner: { ...params.row.businessPartner, postal: params.value },
      }),
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        params?.row?.businessPartner?.postal,
    },
    {
      field: 'businessPartner.city',
      headerName: t('businessPartner:city'),
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.businessPartner?.city,
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        businessPartner: { ...params.row.businessPartner, city: params.value },
      }),
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        params?.row?.businessPartner?.city,
    },
    {
      field: 'businessPartner.region',
      headerName: t('businessPartner:region'),
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.businessPartner?.region,
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        businessPartner: { ...params.row.businessPartner, region: params.value },
      }),
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        params?.row?.businessPartner?.region,
    },
    {
      field: 'businessPartner.country',
      headerName: t('businessPartner:country'),
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => params.row.businessPartner?.country,
      valueSetter: (params: GridValueSetterParams) => ({
        ...params.row,
        businessPartner: { ...params.row.businessPartner, country: params.value },
      }),
      groupingValueGetter: (params: GridGroupingValueGetterParams) =>
        params?.row?.businessPartner?.country,
    },
    {
      field: 'foreignCurrency',
      headerName: `${t('invoice:foreignCurrency')}`,
      width: 150,
      type: 'string',
      editable: true,
      renderEditCell: RenderBasicEditCell,
    },
    {
      field: 'foreignNetAmount',
      headerName: `${t('invoice:foreignNetAmount')}`,
      width: 150,
      type: 'number',
      editable: true,
      valueFormatter: ({ value }) => {
        if (value === null) {
          return '';
        }

        return numberFormatter.format(value);
      },
      valueGetter: (params) => {
        if (isGroupTypeColumn(params) || isAutoGeneratedColumn(params)) {
          return null;
        }

        return params.value;
      },
      groupingValueGetter: (params) => {
        const { foreignNetAmount } = params.row;
        if (Number.isNaN(foreignNetAmount)) {
          return null;
        }

        return numberFormatter.format(foreignNetAmount);
      },
      renderEditCell: RenderBasicEditCell,
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'foreignVatAmount',
      headerName: `${t('invoice:foreignVatAmount')}`,
      width: 150,
      type: 'number',
      editable: true,
      valueFormatter: ({ value }) => {
        if (value === null) {
          return '';
        }

        return numberFormatter.format(value);
      },
      valueGetter: (params) => {
        if (isGroupTypeColumn(params) || isAutoGeneratedColumn(params)) {
          return null;
        }

        return params.value;
      },
      groupingValueGetter: (params) => {
        const { foreignVatAmount } = params.row;

        if (Number.isNaN(foreignVatAmount)) {
          return null;
        }

        return numberFormatter.format(foreignVatAmount);
      },
      renderEditCell: RenderBasicEditCell,
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'exchangeRate',
      headerName: `${t('invoice:exchangeRate')}`,
      width: 150,
      type: 'number',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.value) {
          return params.value;
        }
        return params.value;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString('de-DE')}`;
      },
      valueSetter: (params: GridValueSetterParams) => {
        if (!params.value) {
          return { ...params.row, exchangeRate: params.value };
        }
        return { ...params.row, exchangeRate: params.value };
      },
      groupingValueGetter: (params) => {
        const { exchangeRate } = params.row;

        if (exchangeRate == null) {
          return '';
        }

        return `${exchangeRate.toLocaleString('de-DE')} %`;
      },
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateNumber,
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'vatRate',
      headerName: `${t('invoice:vatRate')} *`,
      width: 150,
      type: 'string',
      editable: true,
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.value) {
          return params.value;
        }
        return params.value * 100;
      },
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toLocaleString('de-DE')} %`;
      },
      valueSetter: (params: GridValueSetterParams) => {
        if (!params.value) {
          return { ...params.row, vatRate: params.value };
        }
        return { ...params.row, vatRate: params.value / 100 };
      },
      groupingValueGetter: (params) => {
        const { vatRate } = params.row;

        if (vatRate == null) {
          return '';
        }

        return `${(vatRate * 100).toLocaleString('de-DE')} %`;
      },
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateNumber,
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'targetCurrency',
      headerName: `${t('invoice:targetCurrency')} *`,
      width: 150,
      type: 'string',
      editable: true,
      renderEditCell: RenderBasicEditCell,
      preProcessEditCellProps: GridFieldsValidator.validateRequiredField,
    },
    {
      field: 'targetNetAmount',
      headerName: `${t('invoice:targetNetAmount')} *`,
      width: 150,
      type: 'number',
      editable: true,
      valueFormatter: ({ value }) => {
        if (value === null) {
          return '';
        }

        return numberFormatter.format(value);
      },
      renderEditCell: RenderBasicEditCell,
      valueGetter: (params) => {
        if (isGroupTypeColumn(params)) {
          return null;
        }

        return params.value;
      },
      groupingValueGetter: (params) => {
        const { targetNetAmount } = params.row;

        if (Number.isNaN(targetNetAmount)) {
          return null;
        }

        return numberFormatter.format(targetNetAmount);
      },
      cellClassName: (params) => getCellClassName(params),
    },
    {
      field: 'targetVatAmount',
      headerName: `${t('invoice:targetVatAmount')} *`,
      width: 150,
      type: 'number',
      editable: true,
      valueFormatter: ({ value }) => {
        if (value === null) {
          return '';
        }

        return numberFormatter.format(value);
      },
      valueGetter: (params) => {
        if (isGroupTypeColumn(params) || isAutoGeneratedColumn(params)) {
          return null;
        }

        return params.value;
      },
      groupingValueGetter: (params) => {
        const { targetVatAmount } = params.row;

        if (Number.isNaN(targetVatAmount)) {
          return null;
        }

        return numberFormatter.format(targetVatAmount);
      },
      renderEditCell: RenderBasicEditCell,
      cellClassName: (params) => getCellClassName(params),
    },
  ];

  if (taxRules) {
    const hasValidationErrors = (taxValidations: TaxValidationModel[]): boolean =>
      taxValidations.some((x: TaxValidationModel) => (x.validationState as number) === 5);

    const hasValidationOrangeWarnings = (taxValidations: TaxValidationModel[]): boolean =>
      taxValidations.some((x: TaxValidationModel) => (x.validationState as number) === 4);

    const hasValidationYellowWarnings = (taxValidations: TaxValidationModel[]): boolean =>
      taxValidations.some((x: TaxValidationModel) => (x.validationState as number) === 3);

    const hasValidationSuccesses = (taxValidations: TaxValidationModel[]): boolean =>
      taxValidations.some((x: TaxValidationModel) => (x.validationState as number) === 2);

    const hasValidationNotRun = (taxValidations: TaxValidationModel[]): boolean =>
      taxValidations.some((x: TaxValidationModel) => (x.validationState as number) === 1);

    const hasValidationShouldNotBeExecuted = (taxValidations: TaxValidationModel[]): boolean =>
      taxValidations.some(
        (x: TaxValidationModel) =>
          (x.validationState as number) === 0 ||
          (x.validationState as number) === undefined ||
          (x.validationState as number) === null,
      );

    const getTaxValidationByTaxRule = (
      taxValidations: TaxValidationModel[],
      taxRule: TaxRuleModel,
    ) => taxValidations.find((x: TaxValidationModel) => x.taxRuleId === taxRule.id);

    const addColumStates = (
      taxValidations: TaxValidationModel[],
      validationState: number,
      invoiceId: string,
    ): void => {
      const filteredValidations = taxValidations.filter(
        (x: TaxValidationModel) => x.validationState === validationState,
      );

      filteredValidations.forEach((validation) => {
        const matchingRule = taxRules.find((rule) => rule.id === validation?.taxRuleId);

        if (matchingRule) {
          matchingRule.targetFields?.forEach((field) => {
            const fieldName = field.charAt(0).toLowerCase() + field.slice(1);

            if (!cellStateMapping[invoiceId]) {
              cellStateMapping[invoiceId] = {};
            }

            cellStateMapping[invoiceId][fieldName] =
              validation?.validationState?.toString() ?? 'none';
          });
        }
      });
    };

    const taxRulesColumns: GridColDef[] = taxRules.map((rule) => {
      const ruleField: GridColDef = {
        field: `${rule.id}`,
        headerName: t('invoice:rule', { name: rule.name }),
        width: 200,
        type: 'string',
        align: 'center',
        valueGetter: (params) => {
          if (isAutoGeneratedColumn(params) && isGroupTypeColumn(params)) {
            return '';
          }

          if (!params.row.taxValidations) {
            return 'none';
          }

          const taxValidation = getTaxValidationByTaxRule(params.row.taxValidations, rule);

          if (taxValidation) {
            return taxValidation.validationState ?? 'none';
          }

          return 'none';
        },
        renderCell: (params) => {
          if (isGroupTypeColumn(params) && !isGroupingColumn(params)) {
            return '';
          }

          if (isGroupTypeColumn(params) && isGroupingColumn(params)) {
            return RenderValidationStateIcon(
              params,
              [t(`validationMessage:row-state-${getGroupingKey(params)}`)],
              params.value,
            );
          }

          if (!params.row.taxValidations) {
            return '';
          }

          const taxValidation = getTaxValidationByTaxRule(params.row.taxValidations, rule);

          return RenderValidationStateIcon(
            params,
            [taxValidation?.validationMessage ?? t(`validationMessage:row-state-${params.value}`)],
            params.value,
          );
        },
        groupingValueGetter: (params) => {
          if (!params.row.taxValidations) {
            return 'none';
          }

          const taxValidation = getTaxValidationByTaxRule(params.row.taxValidations, rule);

          return taxValidation?.validationState ?? 'none';
        },
        valueFormatter: (params) => t(`validationMessages:row-state-${params.value}`),
      };

      return ruleField;
    });

    const validationStateColumn: GridColDef = {
      field: 'validationState',
      headerName: t('validationStates:columnName'),
      width: 75,
      type: 'string',
      align: 'center',
      valueGetter: (params) => {
        if (isAutoGeneratedColumn(params) && isGroupTypeColumn(params)) {
          return 'none';
        }

        const isIgnored = params.row.invoiceState === 0;

        if (!params.row.taxValidations) {
          return 'none';
        }

        const taxValidations = params.row.taxValidations as TaxValidationModel[];

        if (hasValidationErrors(taxValidations)) {
          addColumStates(taxValidations, 5, params.row.id);

          return isIgnored ? 'ignored' : 5;
        }

        if (hasValidationOrangeWarnings(taxValidations)) {
          addColumStates(taxValidations, 4, params.row.id);

          return isIgnored ? 'ignored' : 4;
        }

        if (hasValidationYellowWarnings(taxValidations)) {
          addColumStates(taxValidations, 3, params.row.id);

          return isIgnored ? 'ignored' : 3;
        }

        if (hasValidationSuccesses(taxValidations)) {
          return isIgnored ? 'ignored' : 2;
        }

        if (hasValidationNotRun(taxValidations)) {
          return isIgnored ? 'ignored' : 1;
        }

        if (hasValidationShouldNotBeExecuted(taxValidations)) {
          return isIgnored ? 'ignored' : 0;
        }

        return 'none';
      },
      renderCell: (params) => {
        if (isGroupTypeColumn(params) && !isGroupingColumn(params)) {
          return '';
        }

        if (params.row.companyId !== companyId) {
          return RenderValidationStateIcon(
            params,
            [t(`validationMessage:row-state-moved`)],
            'ignored',
          );
        }

        if (params.row.invoiceState === 0) {
          return RenderValidationStateIcon(
            params,
            [t(`validationMessage:row-state-ignored`)],
            'ignored',
          );
        }

        if (isGroupTypeColumn(params) && isGroupingColumn(params)) {
          return RenderValidationStateIcon(
            params,
            [t(`validationMessage:row-state-${getGroupingKey(params)}`)],
            getGroupingKey(params),
          );
        }

        if (!params.row.taxValidations || params.row.taxValidations.length === 0) {
          return RenderValidationStateIcon(params, [t(`validationMessage:row-state-none`)], 'none');
        }

        const messages = params.row.taxValidations.map(
          (validation: TaxValidationModel) => validation.validationMessage || '',
        );

        return RenderValidationStateIcon(params, messages, params.value);
      },
      groupingValueGetter: (params) => {
        if (params.row.invoiceState === 0) {
          return 'ignored';
        }

        if (!params.row.taxValidations) {
          return 'none';
        }

        const taxValidations = params.row.taxValidations as TaxValidationModel[];

        if (hasValidationErrors(taxValidations)) {
          return 5;
        }

        if (hasValidationYellowWarnings(taxValidations)) {
          return 4;
        }

        if (hasValidationYellowWarnings(taxValidations)) {
          return 3;
        }

        if (hasValidationSuccesses(taxValidations)) {
          return 2;
        }

        if (hasValidationNotRun(taxValidations)) {
          return 1;
        }

        if (hasValidationShouldNotBeExecuted(taxValidations)) {
          return 0;
        }

        return 'none';
      },
      valueFormatter: (params) => t(`validationMessages:row-state-${params.value}`),
    };

    columns.push(...taxRulesColumns);
    columns.splice(2, 0, validationStateColumn);
  }

  const addDefaultRecord = (id: string): GridRowModel => {
    const defaultDate = new Date();
    const invoice: InvoiceModel = {
      id,
      companyId,
      invoiceType: 2,
      invoiceDate: defaultDate.toString(),
      bookingDate: defaultDate.toString(),
      reportingMonth: fillingPeriod?.reportingMonth,
      reportingYear: fillingPeriod?.reportingYear,
      dateOfSupply: defaultDate.toString(),
      businessPartner: {
        businessPartnerType: 2,
      },
      foreignCurrency: 'EUR',
      foreignNetAmount: 0,
      foreignVatAmount: 0,
      exchangeRate: 0,
      targetCurrency: 'EUR',
      targetNetAmount: 0,
      targetVatAmount: 0,
      vatRate: 0.19,
      invoiceState: 1,
      modificationState: 1,
    };

    return invoice;
  };

  const processRowUpdate = async (row: DataGridRow): Promise<DataGridRow> => {
    let resultRow;

    if (row.isNew) {
      resultRow = await InvoicesService.postApiInvoicesAddRow(row as InvoiceModel);
    } else {
      resultRow = await InvoicesService.putApiInvoicesUpdateRow(row as InvoiceModel);
    }

    return resultRow as DataGridRow;
  };

  const processRowDelete = async (row: DataGridRow): Promise<boolean> =>
    InvoicesService.deleteApiInvoicesDeleteRow(row as InvoiceModel);

  const getRows = (pagination: GridPaginationModel) =>
    InvoicesService.getApiInvoicesGetRows(companyId, pagination.page, pagination.pageSize);

  const getRowCount = () => InvoicesService.getApiInvoicesGetRowCount(companyId);

  if (isFetchingFillingPeriod || isFetchingTaxCodes || isFetchingTaxRules) {
    return <CenteredLoader />;
  }

  const moveInvoiceToPreviousPeriod = async (row: DataGridRow): Promise<InvoiceRowModel> =>
    InvoicesService.putApiInvoicesMoveInvoiceToPreviousPeriod(row as InvoiceModel);

  const moveInvoiceToNextPeriod = async (row: DataGridRow): Promise<InvoiceRowModel> =>
    InvoicesService.putApiInvoicesMoveInvoiceToNextPeriod(row as InvoiceModel);

  const isCellEditable = (params: GridCellParams): boolean => {
    if ((params.row.companyId && params.row.companyId !== companyId) || isReadonly) {
      return false;
    }

    return true;
  };

  const showInvoiceActions = (params: GridRowParams): boolean =>
    (params.row.company && params.row.companyId === companyId) || isReadonly;

  // invoice shifted to another period
  const isReadOnlyRow = (params: GridRowParams): boolean =>
    (params.row.company && params.row.companyId !== companyId) || isReadonly;

  return (
    <>
      <BdoDataGrid
        columns={columns}
        getRows={getRows}
        getRowCount={getRowCount}
        processRowUpdate={processRowUpdate}
        processRowDelete={processRowDelete}
        addDefaultRecord={isReadonly ? undefined : addDefaultRecord}
        exportFileName={t('invoice:exportFileName')}
        handleRowClick={handleRowClick}
        moveRowToPreviousTable={moveInvoiceToPreviousPeriod}
        moveRowToNextTable={moveInvoiceToNextPeriod}
        isCellEditable={isCellEditable}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              companyId: false,
              comments: false,
            },
          },
          sorting: {
            sortModel: [
              { field: 'origin', sort: 'asc' },
              { field: 'invoiceDate', sort: 'asc' },
            ],
          },
        }}
        showInvoiceActions={showInvoiceActions}
        isReadOnlyRow={isReadOnlyRow}
        exportAllColumns
      />
      <PopupModal
        title={t('invoice:originalInvoice', { invoiceNumber: modalData?.invoiceNumber })}
        open={modalOpen}
        handleClose={handleCloseModal}
        top="30%"
        left="30%"
      >
        <InvoiceTable invoices={[modalData]} comparisonInvoice={comparisonInvoice} />
      </PopupModal>
    </>
  );
}

export default withTranslation([
  'invoice',
  'gridTooltips',
  'businessPartner',
  'notifications',
  'validationStates',
  'validationMessages',
])(InvoicesGrid);
