import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement } from 'react';
import { withTranslation } from 'react-i18next';

import removeWhitespaces from 'shared/utils/string.helper';

import LoadingButton from 'components/Buttons/LoadingButton/LoadingButton';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import DisplayDeadlines from 'components/DisplayDeadlines/DisplayDeadlines';
import FormFooter from 'components/Layouts/FormFooter/FormFooter';

import { SummaryStepProps } from './SummaryStep.definitions';

function SummaryStep({
  taxFolderStepperForm,
  isSaving = false,
  goToNextStep,
  goToPrevStep,
  t,
}: SummaryStepProps): FunctionComponentElement<SummaryStepProps> {
  const handleNextClick = async () => {
    goToNextStep();
  };

  const handlePrevClick = () => {
    goToPrevStep();
  };

  const getIntervalText = (interval: string) => {
    switch (interval) {
      case '1':
        return t('common:annual');
      case '4':
        return t('common:quarterly');
      case '12':
        return t('common:monthly');
      default:
        return '';
    }
  };

  return (
    <div className="formContent">
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <CardWrapper>
          <Typography variant="h5" component="h3">
            {t('taxFolderDetails')}
          </Typography>
          <TableContainer>
            <Table size="small" aria-label={t('taxFolderDetails')}>
              <TableBody>
                <TableRow
                  key={taxFolderStepperForm.selectedCountry?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{t('navigation:country')}</TableCell>
                  <TableCell align="left">
                    {`${t(
                      `common:${removeWhitespaces(
                        taxFolderStepperForm.selectedCountry?.name ?? '',
                      )}`,
                      taxFolderStepperForm.selectedCountry?.name ?? '',
                    )} (${taxFolderStepperForm.selectedCountry?.isoAlpha2Code})`}
                  </TableCell>
                </TableRow>

                <TableRow
                  key={taxFolderStepperForm.selectedOpenTaxYear?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{t('navigation:tax-year')}</TableCell>
                  <TableCell align="left">
                    {`${taxFolderStepperForm.selectedOpenTaxYear?.year}`}
                  </TableCell>
                </TableRow>

                <TableRow
                  key={taxFolderStepperForm.selectedAdvanceVatReturnInterval}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{t('common:advanceVatReturnInterval')}</TableCell>
                  <TableCell align="left">
                    {getIntervalText(taxFolderStepperForm.selectedAdvanceVatReturnInterval)}
                  </TableCell>
                </TableRow>

                <TableRow
                  key={taxFolderStepperForm.selectedEcSalesListInterval}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{t('common:ecSalesListInterval')}</TableCell>
                  <TableCell align="left">
                    {getIntervalText(taxFolderStepperForm.selectedEcSalesListInterval)}
                  </TableCell>
                </TableRow>

                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{t('permanentPeriodExtension')}</TableCell>
                  <TableCell align="left">
                    {t(`common:${taxFolderStepperForm.permanentPeriodExtension.toString()}`)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardWrapper>
        <DisplayDeadlines deadlines={taxFolderStepperForm.calculatedDeadlines} />
      </Box>

      <FormFooter>
        <Stack direction="row" spacing={3}>
          <Button variant="contained" disabled={isSaving} onClick={handlePrevClick}>
            {t('common:back')}
          </Button>
          <LoadingButton title={t('common:save')} isLoading={isSaving} onClick={handleNextClick} />
        </Stack>
      </FormFooter>
    </div>
  );
}

export default withTranslation(['createTaxFolderStepper', 'common', 'navigation'])(
  observer(SummaryStep),
);
