import { Button, CircularProgress, MenuItem } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4, v4 } from 'uuid';

import { ApprovalStatus, ApprovalWorkflowService, ValidationState } from 'api/taxCalculation';

import useStore from 'context/Store/Store.hooks';
import useUserRoles from 'shared/api-hooks/useUserRoles';
import useValidateTaxForm from 'shared/api-hooks/useValidateTaxForm';
import { isAuditorEmployeeRole, isClientRole } from 'shared/utils/roles.helper';

import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import TaxOfficeSubmissionDialog from 'components/TaxOfficeSubmissionDialog/TaxOfficeSubmissionDialog';

import NamedApprovalStatus from 'enums/NamedApprovalStatus';
import Severity from 'enums/Severity';
import { TaxFormData } from 'models/TaxFormData';

import CorrectionButton from '../CorrectionButton/CorrectionButton';

import { ApprovalWorkflowMenuProps } from './ApprovalWorkflowMenu.definitions';

function ApprovalWorkflowMenu({
  submissionId,
  approvalStatus,
  validationState,
  submitTaxForm,
  validateTaxForm,
  createCorrection,
  t,
}: ApprovalWorkflowMenuProps): FunctionComponentElement<ApprovalWorkflowMenuProps> {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isMutating, setIsMutating] = useState(false);

  const { toastStore } = useStore();
  const { keycloak } = useKeycloak();
  const queryClient = useQueryClient();

  const isApprovalGreen = validationState === ValidationState._2;

  const handleOpenCompletionDialog = () => setOpenDialog(true);
  const handleCloseCompletionDialog = () => setOpenDialog(false);

  const { isFetching: isFetchingUserRoles, data: roles } = useUserRoles(keycloak.subject);

  const { mutateAsync: validate } = useValidateTaxForm(validateTaxForm, submissionId);

  const isAuditorEmployee = isAuditorEmployeeRole(roles ?? []);
  const isClient = isClientRole(roles ?? []);

  const isMenuDisabled =
    approvalStatus === NamedApprovalStatus.InEditing ||
    approvalStatus === NamedApprovalStatus.ApprovalCompleted ||
    approvalStatus === NamedApprovalStatus.CorrectionCreated ||
    isMutating ||
    isAuditorEmployee;

  const sendMessage = useCallback(
    (success: boolean) => {
      if (success) {
        toastStore.addToast({
          id: uuidv4(),
          title: t('status-change-success'),
          severity: Severity.Info,
        });
      } else {
        toastStore.addToast({
          id: uuidv4(),
          title: t('status-change-error'),
          severity: Severity.Error,
        });
      }
    },
    [t, toastStore],
  );

  const sendSubmitMessage = useCallback(
    (responseCode: number) => {
      if (responseCode !== 0) {
        toastStore.addToast({
          id: uuidv4(),
          title: t('submission-fail'),
          severity: Severity.Error,
        });
      } else {
        toastStore.addToast({
          id: uuidv4(),
          title: t('submission-success'),
          severity: Severity.Info,
        });
      }
    },
    [t, toastStore],
  );

  const { mutate: changeApprovalStatus } = useMutation({
    mutationFn: async (nextStatus: NamedApprovalStatus) => {
      const response = await ApprovalWorkflowService.putApiApprovalWorkflowChangeApprovalStatus(
        submissionId,
        ApprovalStatus[`_${nextStatus}`],
      );
      return response;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['BdoFormDataGrid', submissionId], (oldData: TaxFormData) => {
        const updatedData = { ...oldData, approvalStatus: data.approvalStatus };
        return updatedData;
      });
      sendMessage(true);
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    onError: () => sendMessage(false),
    mutationKey: ['changeApprovalStatus', submissionId, approvalStatus],
  });

  const { mutateAsync: submitForm } = useMutation({
    mutationFn: async () => {
      const response = submitTaxForm();

      return response;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['BdoFormDataGrid', submissionId], (oldData: TaxFormData) => {
        const updatedData = { ...data };
        return updatedData;
      });
      sendSubmitMessage(data.submissionStatus ?? 1);
    },
    onMutate: () => setIsMutating(true),
    onSettled: () => setIsMutating(false),
    onError: () => {
      sendSubmitMessage(1);
      setIsMutating(false);
    },
    mutationKey: ['changeApprovalStatus', submissionId, approvalStatus],
  });

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleCloseCompletionDialog();

    const submitResponse = await submitForm();

    if (submitResponse.validationState === ValidationState._2) {
      changeApprovalStatus(NamedApprovalStatus.ApprovalCompleted);
    }
  };

  const handleApprovalChange = useCallback(
    async (nextStatus: NamedApprovalStatus) => {
      if (nextStatus === NamedApprovalStatus.FirstSupervisorApprovalRequested) {
        setIsMutating(true);
        const validationResponse = await validate();
        setIsMutating(false);
        if (validationResponse.validationState !== ValidationState._2) {
          return;
        }
      }
      changeApprovalStatus(nextStatus);
    },
    [validate, changeApprovalStatus],
  );

  const handleMutation = (state: boolean) => setIsMutating(state);

  const allowedApprovals = useMemo(
    () => ({
      isFirstApprovalAllowed: isApprovalGreen && !isClient,
      isSecondApprovalAllowed: isApprovalGreen && !isAuditorEmployee && !isClient,
      isClientApprovalAllowed: isApprovalGreen && !isAuditorEmployee && !isClient,
      isSendToTaxOfficeAllowed: isApprovalGreen && !isAuditorEmployee,
      isDeclineAllowedAuditor: !isAuditorEmployee && !isClient,
      isDeclineAllowedClient: !isAuditorEmployee,
    }),
    [isApprovalGreen, isAuditorEmployee, isClient],
  );

  const getDefaultButton = useCallback((): ReactNode => {
    if (approvalStatus === NamedApprovalStatus.InEditing) {
      return (
        <Button
          onClick={() => handleApprovalChange(NamedApprovalStatus.FirstSupervisorApprovalRequested)}
          disabled={!allowedApprovals.isFirstApprovalAllowed}
        >
          {t('btn-request-first-supervisor-approval')}
        </Button>
      );
    }

    if (
      approvalStatus === NamedApprovalStatus.FirstSupervisorApprovalRequested &&
      allowedApprovals.isSecondApprovalAllowed
    ) {
      return (
        <Button
          onClick={() =>
            handleApprovalChange(NamedApprovalStatus.SecondSupervisorApprovalRequested)
          }
          disabled={!allowedApprovals.isSecondApprovalAllowed}
        >
          {t('btn-request-second-supervisor-approval')}
        </Button>
      );
    }

    if (
      approvalStatus === NamedApprovalStatus.SecondSupervisorApprovalRequested &&
      allowedApprovals.isClientApprovalAllowed
    ) {
      return (
        <Button
          onClick={() => handleApprovalChange(NamedApprovalStatus.ClientApprovalRequested)}
          disabled={!allowedApprovals.isClientApprovalAllowed}
        >
          {t('btn-request-client-approval')}
        </Button>
      );
    }

    if (
      approvalStatus === NamedApprovalStatus.ClientApprovalRequested &&
      allowedApprovals.isSendToTaxOfficeAllowed
    ) {
      return (
        <Button
          onClick={handleOpenCompletionDialog}
          disabled={!allowedApprovals.isSendToTaxOfficeAllowed}
        >
          {t('btn-send-to-tax-office')}
        </Button>
      );
    }

    if (
      approvalStatus === NamedApprovalStatus.ApprovalCompleted ||
      approvalStatus === NamedApprovalStatus.CorrectionCreated
    ) {
      return (
        <CorrectionButton
          createCorrection={createCorrection}
          isCreated={approvalStatus === NamedApprovalStatus.CorrectionCreated}
          handleMutation={handleMutation}
        />
      );
    }

    return <Button disabled>{t('btn-waiting-for-approval')}</Button>;
  }, [
    approvalStatus,
    allowedApprovals.isSecondApprovalAllowed,
    allowedApprovals.isClientApprovalAllowed,
    allowedApprovals.isSendToTaxOfficeAllowed,
    allowedApprovals.isFirstApprovalAllowed,
    t,
    handleApprovalChange,
    createCorrection,
  ]);

  const renderMenuItems = useCallback((): ReactNode[] => {
    switch (approvalStatus) {
      case NamedApprovalStatus.InEditing:
        // only auditor employee
        return [];
      case NamedApprovalStatus.FirstSupervisorApprovalRequested:
        // only supervisor
        return [
          <MenuItem
            key={v4()}
            onClick={() => handleApprovalChange(NamedApprovalStatus.InEditing)}
            disabled={!allowedApprovals.isDeclineAllowedAuditor}
          >
            {t('btn-decline-request')}
          </MenuItem>,

          <MenuItem
            key={v4()}
            onClick={() =>
              handleApprovalChange(NamedApprovalStatus.SecondSupervisorApprovalRequested)
            }
            disabled={!allowedApprovals.isSecondApprovalAllowed}
          >
            {t('btn-request-second-supervisor-approval')}
          </MenuItem>,
          <MenuItem
            key={v4()}
            onClick={() => handleApprovalChange(NamedApprovalStatus.ClientApprovalRequested)}
            disabled={!allowedApprovals.isSecondApprovalAllowed}
          >
            {t('btn-request-client-approval')}
          </MenuItem>,
          <MenuItem
            key={v4()}
            onClick={handleOpenCompletionDialog}
            disabled={!allowedApprovals.isSendToTaxOfficeAllowed}
          >
            {t('btn-send-to-tax-office')}
          </MenuItem>,
        ];
      case NamedApprovalStatus.SecondSupervisorApprovalRequested:
        // only supervisor
        return [
          <MenuItem
            key={v4()}
            onClick={() => handleApprovalChange(NamedApprovalStatus.InEditing)}
            disabled={!allowedApprovals.isDeclineAllowedAuditor}
          >
            {t('btn-decline-request')}
          </MenuItem>,
          <MenuItem
            key={v4()}
            onClick={() => handleApprovalChange(NamedApprovalStatus.ClientApprovalRequested)}
            disabled={!allowedApprovals.isClientApprovalAllowed}
          >
            {t('btn-request-client-approval')}
          </MenuItem>,
          <MenuItem
            key={v4()}
            onClick={handleOpenCompletionDialog}
            disabled={!allowedApprovals.isSendToTaxOfficeAllowed}
          >
            {t('btn-send-to-tax-office')}
          </MenuItem>,
        ];
      case NamedApprovalStatus.ClientApprovalRequested:
        // only client
        return [
          <MenuItem
            key={v4()}
            onClick={() => handleApprovalChange(NamedApprovalStatus.InEditing)}
            disabled={!allowedApprovals.isDeclineAllowedClient}
          >
            {t('btn-decline-request')}
          </MenuItem>,
          <MenuItem
            key={v4()}
            onClick={handleOpenCompletionDialog}
            disabled={!allowedApprovals.isSendToTaxOfficeAllowed}
          >
            {t('btn-send-to-tax-office')}
          </MenuItem>,
        ];
      default:
        return [];
    }
  }, [
    approvalStatus,
    allowedApprovals.isDeclineAllowedAuditor,
    allowedApprovals.isSecondApprovalAllowed,
    allowedApprovals.isSendToTaxOfficeAllowed,
    allowedApprovals.isClientApprovalAllowed,
    allowedApprovals.isDeclineAllowedClient,
    t,
    handleApprovalChange,
  ]);

  const defaultButton = getDefaultButton();
  const menuItems = renderMenuItems();

  if (isFetchingUserRoles) {
    return <CircularProgress />;
  }

  return (
    <>
      <DropdownMenu
        menuDisabled={isMenuDisabled}
        defaultButton={defaultButton}
        menuItems={menuItems}
        isLoading={isMutating}
      />
      <TaxOfficeSubmissionDialog
        open={openDialog}
        onClose={handleCloseCompletionDialog}
        onFormSubmit={handleFormSubmit}
        showClientWarning={approvalStatus !== 3}
      />
    </>
  );
}

export default withTranslation('submissions')(ApprovalWorkflowMenu);
