import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Paper, { PaperProps } from '@mui/material/Paper';
import { FunctionComponentElement, useRef } from 'react';
import Draggable from 'react-draggable';
import { withTranslation } from 'react-i18next';

import { PopupModalProps } from './PopupModal.definitions';

function PaperComponent(props: PaperProps) {
  return (
    <Paper {...props} style={{ margin: 0, padding: 5, width: 'inherit', maxHeight: '750px' }} />
  );
}

function PopupModal({
  open,
  title,
  handleClose,
  top,
  bottom,
  left,
  right,
  children,
  t,
}: PopupModalProps): FunctionComponentElement<PopupModalProps> {
  const nodeRef = useRef(null);

  return (
    <Draggable
      handle='[class*="MuiDialog-root"]'
      cancel='[class*="MuiDialogContent-root"]'
      nodeRef={nodeRef}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        hideBackdrop
        disableScrollLock
        PaperComponent={PaperComponent}
        maxWidth="lg"
        style={{
          top,
          left,
          bottom,
          right,
          overflow: 'auto',
        }}
        ref={nodeRef}
        sx={{
          '& .MuiDialog-container': {
            width: '100%',
          },
        }}
      >
        <DialogTitle style={{ cursor: 'move' }}>{title}</DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {t('actions:close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Draggable>
  );
}

export default withTranslation(['common', 'actions'])(PopupModal);
