/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemInformationModel } from '../models/SystemInformationModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SystemInformationsService {
  /**
   * Retrieves the current system version and environment information.
   * @returns SystemInformationModel Success
   * @throws ApiError
   */
  public static getApiSystemInformations(): CancelablePromise<SystemInformationModel> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/SystemInformations',
    });
  }
}
