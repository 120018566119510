import { useQuery } from '@tanstack/react-query';

import { ImportService } from 'api/import';

const getUnfinishedJobQuery = async (companyId?: string) => {
  if (!companyId) {
    return null;
  }

  const response = await ImportService.getApiImportDoesUnfinishedImportExist(companyId);
  return response;
};

const useUnfinishedImportExist = (companyId?: string) =>
  useQuery({
    queryKey: ['getUnfinishedImportExist', companyId],
    queryFn: async () => getUnfinishedJobQuery(companyId),
  });

export default useUnfinishedImportExist;
