import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  GridCellParams,
  GridColDef,
  GridEditBooleanCell,
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-premium';
import { t } from 'i18next';

import freeTextKeyFigures from 'configs/taxForms-config';

import { TaxFormEntryModel, TaxFormEntryType } from 'api/taxCalculation';

import TaxFormSectionType from 'enums/TaxFormSectionType';

import {
  entryTypeWithAmounts,
  isBooleanValueChanged,
  isValueChanged,
  readOnlyEntriesKeyFigures,
  renderAmountCell,
  renderEditAmountCell,
  taxFormSectionTypes,
  updateRowWithOverwriting,
} from './BdoTaxFormDataGrid.helper';

export default function getColumns(isReadOnlyMode: boolean): GridColDef[] {
  return [
    {
      field: 'configuration.description',
      headerName: t('gridForms:description'),
      flex: 1,
      colSpan: (params: GridCellParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration) {
          return 1;
        }

        // Section header rows
        return configuration.taxFormEntryType === TaxFormEntryType._0 ? 9 : 1;
      },
      valueGetter: (params: GridValueGetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration) {
          return null;
        }
        return configuration.description;
      },
    },
    {
      field: 'calculation.keyFigureForValue',
      headerName: t('gridForms:keyFigure'),
      width: 80,
      align: 'center',
      valueGetter: (params: GridValueGetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration, calculation },
        } = params;

        if (!configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.General) {
          return t('gridForms:keyFigure');
        }

        if (!calculation || configuration.taxFormEntryType === TaxFormEntryType._1) {
          return null;
        }

        return calculation.keyFigureForValue;
      },
    },
    {
      field: 'value',
      headerName: t('gridForms:value'),
      width: 200,
      editable: true,
      valueFormatter: (params: GridValueFormatterParams<TaxFormEntryModel>) => {
        const { api } = params;
        if (!params.id) {
          return params.value;
        }
        const row = api.getRow(params.id);
        const { configuration } = row;

        // User Input Boolean
        if (configuration.taxFormEntryType === TaxFormEntryType._9) {
          return params.value ? t('common:true') : t('common:false');
        }

        return params.value;
      },
      cellClassName: (params: GridCellParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration || isReadOnlyMode) {
          return '';
        }

        // Value only
        if (configuration.taxFormEntryType === TaxFormEntryType._1) {
          if (
            configuration.keyFigureForValue &&
            readOnlyEntriesKeyFigures.includes(configuration.keyFigureForValue)
          ) {
            return '';
          }
          return 'editableCell';
        }

        // User Input Boolean & User Input Integer & User Input String
        if (
          configuration.taxFormEntryType === TaxFormEntryType._9 ||
          configuration.taxFormEntryType === TaxFormEntryType._12 ||
          configuration.taxFormEntryType === TaxFormEntryType._18 ||
          freeTextKeyFigures.includes(configuration.keyFigureForAmount || '')
        ) {
          return 'editableCell';
        }

        return '';
      },
      renderCell: (params: GridRenderCellParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration) {
          return null;
        }

        // ValueOnly and UserInputInteger and UserInputString
        // Viewable for general headers
        if (
          configuration.taxFormEntryType === TaxFormEntryType._1 ||
          configuration.taxFormEntryType === TaxFormEntryType._12 ||
          configuration.taxFormEntryType === TaxFormEntryType._18 ||
          configuration.id === TaxFormSectionType.General ||
          freeTextKeyFigures.includes(configuration.keyFigureForAmount || '')
        ) {
          return <div>{params.value}</div>;
        }

        // UserInputBoolean Cell
        if (configuration.taxFormEntryType === TaxFormEntryType._9) {
          return <div>{params.value ? <CheckIcon /> : <CloseIcon />}</div>;
        }

        return null;
      },
      renderEditCell: (params: GridRenderEditCellParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.General) {
          return <GridEditInputCell {...params} disabled />;
        }

        if (
          configuration.taxFormEntryType === TaxFormEntryType._1 ||
          freeTextKeyFigures.includes(configuration.keyFigureForAmount || '')
        ) {
          return (
            <GridEditInputCell
              {...params}
              disabled={
                params.row?.configuration?.keyFigureForValue &&
                readOnlyEntriesKeyFigures.includes(params.row?.configuration?.keyFigureForValue)
              }
            />
          );
        }

        if (configuration.taxFormEntryType === TaxFormEntryType._9) {
          return <GridEditBooleanCell {...params} />;
        }

        if (configuration.taxFormEntryType === TaxFormEntryType._12) {
          return <GridEditInputCell {...params} type="number" />;
        }

        if (configuration.taxFormEntryType === TaxFormEntryType._18) {
          return <GridEditInputCell {...params} type="text" />;
        }

        return null;
      },
      valueGetter: (params: GridValueGetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration, calculation, overwriting },
        } = params;

        if (!configuration) {
          return null;
        }

        // Header
        if (configuration.id && taxFormSectionTypes.includes(configuration.id)) {
          return t('gridForms:value');
        }

        // For Type ValueOnly
        if (calculation && configuration.taxFormEntryType === TaxFormEntryType._1) {
          // Read only entry i.e. Reporting Year and Month
          if (
            configuration.keyFigureForValue &&
            readOnlyEntriesKeyFigures.includes(configuration.keyFigureForValue)
          ) {
            return calculation.value;
          }
          // Editable value entries
          return overwriting ? overwriting.value : calculation.value;
        }

        // For Type UserInputInteger and UserInputString
        if (
          calculation &&
          (configuration.taxFormEntryType === TaxFormEntryType._12 ||
            configuration.taxFormEntryType === TaxFormEntryType._18 ||
            freeTextKeyFigures.includes(configuration.keyFigureForAmount || ''))
        ) {
          // Editable value entries
          return overwriting ? overwriting.value : calculation.value;
        }

        // For Type UserInputBoolean
        if (calculation && configuration.taxFormEntryType === TaxFormEntryType._9) {
          return overwriting
            ? parseInt(overwriting.value || '', 10) || 0
            : parseInt(calculation.value || '', 10) || 0;
        }

        return null;
      },
      valueSetter: (params: GridValueSetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration) {
          return null;
        }

        // For Type ValueOnly, UserInputInteger, UserInputString
        if (
          configuration.taxFormEntryType === TaxFormEntryType._12 ||
          configuration.taxFormEntryType === TaxFormEntryType._18 ||
          (configuration.taxFormEntryType === TaxFormEntryType._1 &&
            !readOnlyEntriesKeyFigures.includes(configuration.keyFigureForValue || '')) ||
          freeTextKeyFigures.includes(configuration.keyFigureForAmount || '')
        ) {
          if (!isValueChanged(params, 'value')) {
            return params.row;
          }
          return updateRowWithOverwriting(params, 'value', params.value);
        }

        // For Type UserInputBoolean
        if (configuration.taxFormEntryType === TaxFormEntryType._9) {
          const valueToBeUpdated = params.value === true ? '1' : '0';
          if (!isBooleanValueChanged(params, 'value', valueToBeUpdated)) {
            return params.row;
          }
          return updateRowWithOverwriting(params, 'value', valueToBeUpdated);
        }

        return params.row;
      },
    },
    {
      field: 'keyFigureForAmount',
      headerName: t('gridForms:keyFigure'),
      minWidth: 80,
      maxWidth: 200,
      align: 'center',
      valueGetter: (params: GridValueGetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration, calculation },
        } = params;

        if (!configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.Tax) {
          return t('gridForms:keyFigure');
        }

        return calculation ? calculation.keyFigureForAmount : null;
      },
    },
    {
      field: 'amount',
      headerName: t('gridForms:amount'),
      width: 120,
      type: 'number',
      align: 'center',
      editable: true,
      renderCell: renderAmountCell,
      renderEditCell: renderEditAmountCell,
      cellClassName: (params: GridCellParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration || isReadOnlyMode) {
          return '';
        }

        if (
          configuration.taxFormEntryType &&
          entryTypeWithAmounts.includes(configuration.taxFormEntryType)
        ) {
          return 'editableCell';
        }
        return '';
      },
      valueGetter: (params: GridValueGetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration, calculation, overwriting },
        } = params;

        if (!configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.Tax) {
          return t('gridForms:value');
        }

        if (
          calculation &&
          configuration.taxFormEntryType &&
          entryTypeWithAmounts.includes(configuration.taxFormEntryType)
        ) {
          return overwriting?.amount ?? calculation.amount;
        }

        return null;
      },
      valueSetter: (params: GridValueSetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration) {
          return null;
        }

        if (
          configuration.taxFormEntryType &&
          entryTypeWithAmounts.includes(configuration.taxFormEntryType)
        ) {
          if (!isValueChanged(params, 'amount')) {
            return params.row;
          }
          return updateRowWithOverwriting(params, 'amount', params.value);
        }

        return params.row;
      },
    },
    {
      field: 'keyFigureForTax',
      headerName: t('gridForms:keyFigure'),
      width: 80,
      align: 'center',
      valueGetter: (params: GridValueGetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration, calculation },
        } = params;

        if (!configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.Tax) {
          return t('gridForms:keyFigure');
        }
        return calculation ? calculation.keyFigureForTax : null;
      },
    },
    {
      field: 'taxAmount',
      headerName: t('gridForms:taxAmount'),
      width: 120,
      type: 'number',
      align: 'center',
      editable: true,
      renderCell: renderAmountCell,
      renderEditCell: renderEditAmountCell,
      cellClassName: (params: GridCellParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration || isReadOnlyMode) {
          return '';
        }

        if (
          configuration.taxFormEntryType &&
          entryTypeWithAmounts.includes(configuration.taxFormEntryType)
        ) {
          return 'editableCell';
        }
        return '';
      },
      valueGetter: (params: GridValueGetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration, calculation, overwriting },
        } = params;

        if (!configuration) {
          return null;
        }

        if (configuration.id === TaxFormSectionType.Tax) {
          return t('gridForms:value');
        }

        if (
          calculation &&
          configuration.taxFormEntryType &&
          entryTypeWithAmounts.includes(configuration.taxFormEntryType)
        ) {
          return overwriting ? overwriting.taxAmount : calculation.taxAmount;
        }
        return null;
      },
      valueSetter: (params: GridValueSetterParams<TaxFormEntryModel>) => {
        const {
          row: { configuration },
        } = params;

        if (!configuration) {
          return null;
        }

        if (
          configuration.taxFormEntryType &&
          entryTypeWithAmounts.includes(configuration.taxFormEntryType)
        ) {
          if (!isValueChanged(params, 'taxAmount')) {
            return params.row;
          }
          return updateRowWithOverwriting(params, 'taxAmount', params.value);
        }

        return params.row;
      },
    },
  ];
}
