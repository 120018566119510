import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { useKeycloak } from '@react-keycloak/web';
import { QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import 'configs/i18n';
import { Headers } from 'form-data';
import { observer } from 'mobx-react-lite';
import { Suspense, useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import initializeOpenApi from 'configs/openApi-config';
import initializeQueryClient from 'configs/queryClient-config';

import useStore from 'context/Store/Store.hooks';
import { logError } from 'shared/utils/error.helper';

import AppLoader from 'components/AppLoader/AppLoader';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import Router from 'components/Router/Router';

import AppRouterConfig from './AppRouterConfig';
import ClientSelectionWrapper from './ClientSelectionWrapper';

LicenseInfo.setLicenseKey(
  '56c58d16da5c178333b1432c7ab51d32Tz04MzY4NyxFPTE3Mzg4MzI4MDEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

function App() {
  const { keycloak, initialized } = useKeycloak();
  const { hubStore, notificationStore, clientStore } = useStore();

  const queryClient = initializeQueryClient(notificationStore);

  const resolveToken = useCallback(async (): Promise<string> => {
    await keycloak.updateToken(70);

    if (!keycloak.token) {
      throw new Error('Token is undefined');
    }

    return keycloak.token;
  }, [keycloak]);

  const resolveHeaders = useCallback(async (): Promise<Headers> => {
    const headers: Headers = {};
    const clientId = clientStore.getClient()?.id;

    if (clientId) {
      headers['x-tenant-id'] = clientId;
    }

    return headers;
  }, [clientStore]);

  useEffect(() => {
    if (!keycloak.authenticated) return;

    initializeOpenApi(resolveToken, resolveHeaders);

    if (keycloak.token) {
      hubStore.startHubConnections(resolveToken);
    }
  }, [hubStore, keycloak.authenticated, keycloak.token, resolveToken, resolveHeaders]);

  if (!initialized) {
    return <AppLoader />;
  }

  if (!keycloak || !keycloak.token || !keycloak.authenticated) {
    return <div>Authentication Failed - Keycloak instance unavailable</div>;
  }
  return (
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary
                onReset={reset}
                onError={logError}
                FallbackComponent={FallbackComponent}
              >
                <Suspense fallback={<AppLoader />}>
                  <ClientSelectionWrapper>
                    <Router routes={AppRouterConfig} />
                  </ClientSelectionWrapper>
                </Suspense>
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </BrowserRouter>
      </QueryClientProvider>
    </StyledEngineProvider>
  );
}

export default observer(App);
