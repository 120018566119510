import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { FunctionComponentElement, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { logError } from 'shared/utils/error.helper';

import logo from 'assets/images/logo.jpg';

import ActiveClientDisplay from 'components/ActiveClientDisplay/ActiveClientDisplay';
import FallbackComponent from 'components/FallbackComponent/FallbackComponent';
import Loader from 'components/Loader/Loader';
import SystemInformations from 'components/SystemInformations/SystemInformations';
import UserMenu from 'components/UserMenu/UserMenu';

import { HeaderProps } from './Header.definitions';

function Header({
  title,
  handleDrawerToggle,
  t,
}: HeaderProps): FunctionComponentElement<HeaderProps> {
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      color="secondary"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ pr: { xs: 1, sm: 0.5 }, pl: { xs: 1, sm: 2 } }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <IconButton size="small" sx={{ m: 0, p: 0 }} onClick={() => navigate('/dashboard')}>
          <Avatar src={logo} alt="logo" variant="square" sx={{ width: '100%', height: '40px' }} />
        </IconButton>
        <Typography variant="h5" sx={{ mx: 2, flexGrow: 1 }}>
          {title}
        </Typography>

        {/* <CopyToken />
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: 'grey.500', borderWidth: '1px', mx: 1 }}
        /> */}
        <SystemInformations />
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: 'grey.500', borderWidth: '1px', mx: 1 }}
        />
        <ActiveClientDisplay />
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ borderColor: 'grey.500', borderWidth: '1px', mx: 1 }}
        />
        <QueryErrorResetBoundary>
          {({ reset }) => (
            <ErrorBoundary onReset={reset} onError={logError} FallbackComponent={FallbackComponent}>
              <Suspense fallback={<Loader />}>
                <UserMenu />
              </Suspense>
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </Toolbar>
    </AppBar>
  );
}

export default withTranslation('common')(observer(Header));
