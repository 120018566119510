import { AxiosError } from 'axios';
import { ErrorInfo } from 'react';

import i18n from 'configs/i18n';

import { ApiError } from 'api/client';

export const logError = (error: Error, info: ErrorInfo) => {
  // eslint-disable-next-line no-console
  console.error(error);
  // eslint-disable-next-line no-console
  console.error(info);
};

export const isApiError = (error: Error): error is ApiError =>
  error &&
  'name' in error &&
  'url' in error &&
  'status' in error &&
  'statusText' in error &&
  'body' in error &&
  'request' in error;

export const handleErrorMessage = (error: Error): string => {
  if (error instanceof AxiosError) {
    if (error.response) {
      if (error.response.data.detail) {
        return error.response.data.detail;
      }
      return error.message;
    }
    if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
      return i18n.t('common:noServiceMessage');
    }
    return error.message;
  }

  if (isApiError(error)) {
    return error.message;
  }

  // eslint-disable-next-line no-console
  console.error('Unknown Error', error);
  return 'Unknown Error';
};

export const handleErrorCode = (error: Error) => {
  if (error instanceof AxiosError) {
    if (error.response) {
      if (error.response.data.status) {
        return error.response.data.status;
      }
      return error.response.status;
    }
    if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
      return 500;
    }
    return error.status;
  }

  if (isApiError(error)) {
    return error.status;
  }

  throw new Error('Unknown Error');
};

export const isServiceUnavailableError = (error: Error) => {
  if (error instanceof AxiosError) {
    if (error.response) {
      return error.response.status >= 500;
    }
    if (error.code) {
      return error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK';
    }
  }
  if (isApiError(error)) {
    return error.status >= 500;
  }
  return false;
};
